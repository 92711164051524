import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './Values.css';

function JediCard({ letter, title, descriptions }) {
    return (
        <Card className="my-3 jedi-card">
            <Card.Body>
                <Row className="align-items-center">
                    <Col xs={4} lg={12} className="text-center px-0">
                        <div className="circle-title">
                            <div className="circle">{letter}</div>
                            <Card.Title className="fs-3 no-wrap">
                                {title}
                            </Card.Title>
                        </div>
                    </Col>
                    <Col xs={8} lg={12} className="text-left text-lg-center">
                        {descriptions.map((desc, index) => (
                            <Card.Text
                                key={index}
                                className={index === 1 ? 'italic' : ''}
                            >
                                {desc}
                            </Card.Text>
                        ))}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

export default JediCard;

JediCard.propTypes = {
    letter: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    descriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};
