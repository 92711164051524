import axios from 'axios';

const cacheExpiry = 1 * 60 * 60 * 1000; // 1 hour

export default async function getGravatarData(profileIdentifier) {
    try {
        if (!profileIdentifier) return;

        const cacheKey = `gravatarProfile-${profileIdentifier}`;
        const cachedProfile = localStorage.getItem(cacheKey);

        if (cachedProfile) {
            const { profile, timestamp } = JSON.parse(cachedProfile);
            if (Date.now() - timestamp < cacheExpiry) {
                // eslint-disable-next-line no-console
                console.log('Using Gravatar data from cached memeory.');
                return profile;
            }
        }

        const response = await axios.get(
            `https://api.gravatar.com/v3/profiles/${profileIdentifier}`
        );
        let { display_name, avatar_url, job_title, profile_url } =
            response.data;

        // Check if the avatar is the default image
        const imgUrl = `${avatar_url}?d=404`;
        try {
            await axios.get(imgUrl);
            // If the request succeeds, it means there is a custom Gravatar image.
        } catch (error) {
            // If the request fails with a 404, it means the image is the default one.
            if (error.response && error.response.status === 404) {
                avatar_url = undefined;
            } else {
                // eslint-disable-next-line no-console
                console.error('Error fetching the image:', error);
            }
        }

        const profileData = {
            display_name,
            avatar_url,
            job_title,
            profile_url,
        };

        localStorage.setItem(
            cacheKey,
            JSON.stringify({ profile: profileData, timestamp: Date.now() })
        );

        return profileData;
    } catch (error) {
        return {
            error: error.response
                ? error.response.statusText
                : 'An error occurred',
        };
    }
}
