import React from 'react';
import { Container, Row } from 'react-bootstrap';

import projects from '../assets/project/projects.json';

import './ProjectUpdates.css';
import ProjectCard from './ProjectCard';

export default function ProjectUpdates() {
    return (
        <section id="projectUpdates">
            <Container className="projectUpdates-container py-4">
                <h2 className="projectUpdates-title h1 text-center  mb-5">
                    Project Updates
                </h2>
                <Row className="project-row">
                    {projects
                        .slice()
                        .reverse()
                        .map((project, index) => (
                            <ProjectCard
                                key={index}
                                title={project.title}
                                description={project.description}
                                detail={project.detail}
                            />
                        ))}
                </Row>
            </Container>
        </section>
    );
}
