import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';

import './NavBar.css';

export default function NavBar() {
    // eslint-disable-next-line no-unused-vars
    const [showSearchForm, setShowSearchForm] = useState('hide');
    // eslint-disable-next-line no-unused-vars
    const [showSearchIcon, setShowSearchIcon] = useState('show');
    const [expanded, setExpanded] = useState(false);
    const handleNavClick = () => {
        setExpanded(false);
    };

    return (
        <section id="navbar">
            <Navbar
                expanded={expanded}
                onToggle={() => setExpanded(!expanded)}
                className="bg-body-tertiary"
                expand="xl"
                bg="light"
                sticky="top"
            >
                <Container>
                    <Navbar.Brand as={Link} to="/">
                        <h1 className="h1 text-primary">Sustainability Hub</h1>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="right-aligned" className="">
                        <Nav className={`mr-auto ${showSearchIcon}`}>
                            <Nav.Link
                                as={Link}
                                to="/about"
                                onClick={handleNavClick}
                            >
                                <div className="nav-btn h3">About Us</div>
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/community-events"
                                onClick={handleNavClick}
                            >
                                <div className="nav-btn h3">
                                    Community Events
                                </div>
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/project-updates"
                                onClick={handleNavClick}
                            >
                                <div className="nav-btn h3">
                                    Project Updates
                                </div>
                            </Nav.Link>
                            <Nav.Link
                                as={Link}
                                to="/contact"
                                onClick={handleNavClick}
                            >
                                <div className="nav-btn h3">Contact</div>
                            </Nav.Link>
                        </Nav>
                        {/* The below logic can be uncommented to integrate the search component back
             into the NavBar when needed */}
                        {/* <SearchComponent
               showSearchForm = {showSearchForm}
               setShowSearchForm = {setShowSearchForm}
               showSearchIcon = {showSearchIcon}
               setShowSearchIcon = {setShowSearchIcon}
               />    */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </section>
    );
}
