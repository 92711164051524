import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import environment from '../../assets/img/definition/environment.png';
import economy from '../../assets/img/definition/economy.png';
import society from '../../assets/img/definition/society.png';

import DefinitionCard from './DefinitionCard';
import './Definition.css';

export default function Sustainability() {
    return (
        <section id="definition" className="">
            <Container fluid>
                <Row className="definition-block px-3">
                    <Col className="">
                        <h2 className="definition-title text-center  h1  text-primary">
                            What is Sustainability?
                        </h2>
                        <p className="definition-subtitle fs-3 ">
                            Sustainability is the capacity to maintain and
                            balance <b>environmental, economic, and social </b>{' '}
                            systems over the long term, ensuring that current
                            needs are met without compromising the ability of
                            future generations to meet their own needs.
                        </p>
                    </Col>
                </Row>
                <Row className="system-cards-block">
                    <Col className="system-cards-container">
                        <Col>
                            <DefinitionCard
                                title={'Environment'}
                                description={
                                    'Environmental sustainability involves managing and conserving natural resources to ensure the health ' +
                                    'of ecosystems and mitigate adverse impacts on the environment.'
                                }
                                imgSrc={environment}
                            />
                        </Col>
                        <Col>
                            <DefinitionCard
                                title={'Economy'}
                                description={
                                    'Economic sustainability entails maintaining a stable and resilient economic system that meets present ' +
                                    'needs while fostering long-term growth, prosperity, and equitable distribution of resources.'
                                }
                                imgSrc={economy}
                            />
                        </Col>
                        <Col>
                            <DefinitionCard
                                title={'Society'}
                                description={
                                    'Social sustainability focuses on fostering just and inclusive societies, ensuring the well-being, equity, ' +
                                    'and empowerment of individuals and communities for current and future generations.'
                                }
                                imgSrc={society}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className="system-cards-block-background" />
            </Container>
        </section>
    );
}
