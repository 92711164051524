import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';

import linkedinIcon from '../assets/img/logos/svg/linkedinIcon.svg';
import facebookIcon from '../assets/img/logos/svg/facebookIcon.svg';
import xIcon from '../assets/img/logos/svg/xIcon.svg';
import githubIcon from '../assets/img/logos/svg/githubIcon.svg';
import instagramIcon from '../assets/img/logos/svg/instagramIcon.svg';

import './Footer.css';

export default function Footer() {
    return (
        <div id="footer" className="bg-light">
            <div className="footer-container">
                <Navbar expand="sm" className="footer-navbar">
                    <Container fluid className="bg-light">
                        <Navbar.Brand xm={12} href="/">
                            <h1 className="h1">Sustainability Hub</h1>
                        </Navbar.Brand>
                        <Navbar sm={12} className="footer-right-navbar">
                            <Nav className="footer-right-navbar-top">
                                <Navbar.Text>Connect with us</Navbar.Text>
                            </Nav>
                            <Nav className="footer-right-navbar-socials show">
                                <Nav.Link
                                    id="linkedin"
                                    href="https://www.linkedin.com/company/co-sustainability-hub"
                                >
                                    <img
                                        src={linkedinIcon}
                                        alt="linkedin logo"
                                    />
                                </Nav.Link>
                                <Nav.Link
                                    id="facebook"
                                    href="https://www.facebook.com/cosustainhub"
                                >
                                    <img
                                        src={facebookIcon}
                                        alt="facebook logo"
                                    />
                                </Nav.Link>
                                <Nav.Link
                                    id="x"
                                    href="https://twitter.com/co_sustain_hub"
                                >
                                    <img src={xIcon} alt="X logo" />
                                </Nav.Link>
                                <Nav.Link
                                    id="github"
                                    href="https://github.com/msu-denver/"
                                >
                                    <img src={githubIcon} alt="github logo" />
                                </Nav.Link>
                                <Nav.Link
                                    id="instagram"
                                    href="https://www.instagram.com/co_sustain_hub"
                                >
                                    <img
                                        src={instagramIcon}
                                        alt="instagram logo"
                                    />
                                </Nav.Link>
                            </Nav>
                        </Navbar>
                    </Container>
                </Navbar>
            </div>
            <div className="footer-copyright py-4">
                <p className="h6 copyright"> &copy; Copyright 2024</p>
            </div>
        </div>
    );
}
