import React, { useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './EventCard.css';

import PopUpPage from '../common/popUpPage/PopUpPage';

export default function EventCard({
    index,
    day,
    monthYear,
    title,
    description,
    detail,
}) {
    const [modalShow, setModalShow] = useState(false);

    const setTheme = (ind) => {
        if (ind % 2 === 0) {
            return 'even';
        }
        return 'odd';
    };

    return (
        <>
            <Card className="event-card mb-4">
                <Card.Body className={`${setTheme(index)}`}>
                    <Row>
                        <Col xs="auto">
                            <div className="display-1">{day}</div>
                            <div className="fs-4 mt-n3">{monthYear}</div>
                        </Col>
                        <Col className="d-flex flex-column">
                            <Card.Title className="fs-2">{title}</Card.Title>
                            <Card.Text className="fs-4">
                                {description}
                            </Card.Text>
                            <Button
                                size="sm"
                                className="ms-auto align-center fs-4 px-4 pb-2"
                                onClick={() => setModalShow(true)}
                            >
                                Learn More
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {modalShow && (
                <PopUpPage
                    show={modalShow}
                    detail={detail}
                    event="true"
                    onHide={() => setModalShow(false)}
                />
            )}
        </>
    );
}

// PropTypes definitions
EventCard.propTypes = {
    index: PropTypes.number.isRequired,
    day: PropTypes.string.isRequired,
    monthYear: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    detail: PropTypes.shape({
        title: PropTypes.string,
        imgSrc: PropTypes.string,
        subHeading1: PropTypes.string,
        subHeading2: PropTypes.string,
        subHeading3: PropTypes.string,
        subHeading4: PropTypes.string,
    }),
};
