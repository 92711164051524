import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './ProjectCard.css';
import PopUpPage from '../common/popUpPage/PopUpPage';

export default function ProjectCard({ title, description, detail }) {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <Card className="project-card bg-light mb-5">
                <Card.Body className="project-body p-4">
                    <Card.Title className="fs-1">
                        <div>
                            {title} <div className="title-background" />
                        </div>
                    </Card.Title>
                    <Card.Text className="project-description">
                        {description}
                    </Card.Text>
                    <Button
                        className="fs-4 px-4 py-2"
                        onClick={() => setModalShow(true)}
                    >
                        Learn More
                    </Button>
                </Card.Body>
            </Card>

            {modalShow && (
                <PopUpPage
                    show={modalShow}
                    detail={detail}
                    event="false"
                    onHide={() => setModalShow(false)}
                />
            )}
        </>
    );
}

// PropTypes definitions
ProjectCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    detail: PropTypes.shape({
        imgSrc: PropTypes.string,
        title: PropTypes.string.isRequired,
        body: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
};
