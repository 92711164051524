import React, { useState } from 'react';
import {
    Row,
    Col,
    Form,
    InputGroup,
    FloatingLabel,
    Button,
} from 'react-bootstrap';

import userIcon from '../assets/img/logos/svg/userIcon.svg';
import phoneIcon from '../assets/img/logos/svg/phoneIcon.svg';
import emailIcon from '../assets/img/logos/svg/emailIcon.svg';

import './ContactUs.css';

export default function ContactUs() {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };
    return (
        <div id="contactus">
            <div className="form-container">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-3 form-header">
                        <div className="form-title h1 text-primary">
                            Contact Us
                        </div>
                        <div className="form-description h6">
                            Have a question? Want to learn more or get involved?
                            Please utilize the form below to get in touch with
                            us!
                        </div>
                    </Row>
                    <Row className="mb-3 form-name">
                        <Form.Group
                            as={Col}
                            md="6 mb-3"
                            className=""
                            controlId="validationCustomUsername"
                        >
                            <InputGroup
                                hasValidation
                                className="input-log-label"
                            >
                                <img src={userIcon} alt="" />
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="First Name"
                                    defaultValue="Mark"
                                    className="mb-3 input-div "
                                >
                                    <Form.Control type="text" placeholder="" />
                                </FloatingLabel>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustomUsername"
                        >
                            <InputGroup className="input-log-label">
                                <img src={userIcon} alt="" />
                                <FloatingLabel
                                    className=" mb-3 "
                                    controlId="floatingInput"
                                    label="Last Name"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your last name"
                                    />
                                </FloatingLabel>
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid name.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 form-email">
                        <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom03"
                        >
                            <InputGroup className="input-log-label">
                                <img src={emailIcon} alt="" />

                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="email"
                                        placeholder="name@example.com"
                                    />
                                </FloatingLabel>

                                <Form.Control.Feedback>
                                    Looks good!
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid email.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 form-phone">
                        <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom03"
                        >
                            <InputGroup className="input-log-label">
                                <img src={phoneIcon} alt="" />

                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Phone"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="phoneNumber"
                                        placeholder="303-000-000"
                                    />
                                </FloatingLabel>

                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid phone number.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 form-comment">
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Control
                                as="textarea"
                                placeholder="Let us know how we can help you."
                                style={{
                                    height: '181px',
                                    padding: '24px 32px',
                                }}
                            />
                        </Form.Group>
                    </Row>
                    <Button className="form-send-btn" type="submit">
                        Send
                    </Button>
                </Form>
            </div>
        </div>
    );
}
