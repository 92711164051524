import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './DefinitionCard.css';

export default function SystemCard({ title, description, imgSrc }) {
    return (
        <Card id="system-card" className="my-4 ">
            <Card.Body className="text-center text-primary mb-4">
                <Card.Title className="system-card-title h3">
                    {title}
                </Card.Title>
                <Card.Text className="system-card-description">
                    {description}
                </Card.Text>
            </Card.Body>
            <Card.Img
                className="system-card-image"
                variant="bottom"
                src={imgSrc}
            />
        </Card>
    );
}

SystemCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
};
