import React from 'react';
import { Form, Button, Row, Col, Navbar } from 'react-bootstrap';
import './NavBar.css';
import PropTypes from 'prop-types';

export default function SearchComponent({
    showSearchForm,
    setShowSearchForm,
    showSearchIcon,
    setShowSearchIcon,
}) {
    const handleShowSearchForm = () => {
        if (showSearchForm === 'hide') {
            setShowSearchForm('show');
            setShowSearchIcon('hide');
        } else if (showSearchForm === 'show') {
            setShowSearchForm('hide');
            setShowSearchIcon('show');
        }
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
    };

    const handleCancelSearch = () => {
        setShowSearchIcon('show');
        setShowSearchForm('hide');
    };
    return (
        <>
            <Navbar.Text
                className={showSearchIcon}
                onClick={handleShowSearchForm}
            >
                <i className="fas fa-search" />
            </Navbar.Text>
            <Form className="form-inline">
                <Row className={showSearchForm}>
                    <Col xs="auto">
                        <Form.Control
                            type="text"
                            placeholder="Search"
                            className="mr-sm-2"
                        />
                    </Col>
                    <Col xs="auto">
                        <Button
                            className="search"
                            type="submit"
                            onSubmit={handleSearchSubmit}
                        >
                            <i className="fas fa-search" />
                        </Button>
                        <Button
                            className="cancel"
                            type="reset"
                            onClick={handleCancelSearch}
                        >
                            <i className="fa-solid fa-x" />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}
SearchComponent.propTypes = {
    showSearchForm: PropTypes.string,
    setShowSearchForm: PropTypes.func,
    showSearchIcon: PropTypes.string,
    setShowSearchIcon: PropTypes.func,
};
