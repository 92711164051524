import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Acknowledgments.css';

import nsfImage from '../../assets/img/acknowledgments/USNSF_Logo_Lockup_stacked_RGB_1200ppi.png';

const Acknowledgments = () => (
    <section id="acknowledgments" className="py-5">
        <Container>
            <Row>
                <Col xs={12}>
                    <h2 className="h1 text-center text-primary">
                        Acknowledgments
                    </h2>
                    <p className="fs-4">
                        This project is funded by the U.S. National Science
                        Foundation (NSF) under
                        <a
                            href="https://www.nsf.gov/awardsearch/showAward?AWD_ID=2318730"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            Grant No. 2318730{' '}
                        </a>
                        and the National Artificial Intelligence Research
                        Resource (NAIRR) Pilot under
                        <a
                            href="https://nairrpilot.org/awarded-projects#:~:text=NAIRR240197"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            Grant No. NAIRR240197
                        </a>
                        . We acknowledge the NSF for their support in enabling
                        this transformative work in sustainability, and NAIRR
                        for providing cloud computing resources via Amazon Web
                        Services (AWS) and Microsoft Azure that allow for the
                        integration of advanced machine learning (ML) and
                        natural language processing (NLP) tools into the
                        project. Any opinions, findings, and conclusions or
                        recommendations expressed in this material are those of
                        the project team and do not necessarily reflect the
                        views of the NSF or the NAIRR.
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-center align-items-center">
                <Col xs={8} sm={6} md={5} lg={4} className="text-center">
                    <img src={nsfImage} alt="NSF Logo" className="img-fluid" />
                </Col>
            </Row>
        </Container>
    </section>
);
export default Acknowledgments;
