import React from 'react';
import { Col, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './Leaders.css';

export default function Leaders({ imgSrc, name, title, university, link }) {
    return (
        <Col lg={3} md={4} xs={6} className="leaders-col">
            <Card id="leaders-card" className="leaders-card-container mb-4">
                <Card.Img
                    className="rounded-circle"
                    variant="top"
                    src={`${imgSrc}?size=500`}
                    alt={`${imgSrc.startsWith('http') ? name : `No profile picture for ${name}`}`}
                    style={{ width: '240px', height: '240px' }}
                />
                <Card.Body className="">
                    <Card.Title className="name text-center">
                        {link ? (
                            <a
                                href={link}
                                className="link-dark link-offset-2 text-wrap fw-normal link-underline-opacity-25 link-underline-opacity-100-hover h3"
                            >
                                {name}
                                <span className="visually-hidden">
                                    Click to see profile of {name}.
                                </span>
                            </a>
                        ) : (
                            <span className="text-dark text-wrap text-center fw-normal h3">
                                {name}
                            </span>
                        )}
                    </Card.Title>
                    <Card.Text className="title text-dark text-center fw-normal h6">
                        <div>{title}</div>

                        <div>{university}</div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );
}

// PropTypes definitions imgSrc, name, title, link
Leaders.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    university: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    link: PropTypes.string,
};
