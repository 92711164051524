import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import JediCard from './JediCard';

const descriptions = {
    justice: [
        'Justice refers to the pursuit of fairness and moral righteousness. In the context of social systems, it means ensuring that all ' +
            'individuals and communities have access to the same rights and protections. Unfortunately, many individuals and communities have ' +
            'been historically exploited or excluded in research, leading to significant imbalances in data and policy influence.',
        "The Sustainability Hub actively addresses these imbalances by ensuring that all Coloradans have equal access to the platform's resources. " +
            'By empowering underrepresented groups through data democracy, we drive tangible change and contribute to a more just future.',
    ],
    equity: [
        'Equity involves creating fair treatment and opportunities for all people, while striving to identify and eliminate barriers that have ' +
            'prevented the full participation of some groups.',
        'The Sustainability Hub aims to provide tailored support through a natural-language based query system, designed to be user-friendly for ' +
            'people of all backgrounds, regardless of their technical expertise. By simplifying access to complex data and insights, this system ' +
            'ensures that users can easily navigate and utilize the platform without needing advanced technical skills.',
    ],
    diversity: [
        'Diversity encompasses the presence of differences within a given setting, which could include race, ethnicity, gender, age, disability, ' +
            'socioeconomic status, religion, sexuality, and other identities.',
        'The Sustainability Hub promotes diversity not only through our interdisciplinary team involving multiple Minority-Serving Institutions, ' +
            'but also by engaging Colorado communities through an extensive survey that gathers user experiences and use cases to inform the development of features, ' +
            'functions, and data included in the Hub. This approach, alongside a policy that prioritizes data collection from diverse sources, ensures that our platform ' +
            'is enriched with varied and comprehensive information, ultimately supporting diversity across the state.',
    ],
    inclusivity: [
        'Inclusivity is the practice of creating environments in which any individual or group can be and feel welcomed, respected, supported, ' +
            'and valued to fully participate.',
        'The Sustainability Hub prioritizes inclusivity in both design and operation. We aim to create a welcoming environment for everyone by ' +
            'adhering to accessibility standards, ensuring that our resources are usable by individuals with diverse abilities. We employ User-Centered ' +
            'Design principles to make sure that the needs and perspectives of our users are integrated into every aspect of our platform. Additionally, ' +
            'we are committed to presenting a comprehensive and inclusive set of data that reflects a variety of viewpoints, inherently promoting ' +
            'inclusivity.',
    ],
};

const Values = () => (
    <section id="values" className="pb-5 bg-light">
        <Container className="">
            <Row className="mb-5 values-row px-3">
                <Col xs={12}>
                    <h2 className="h1 text-center text-primary">Our Values</h2>
                    <p className="fs-4">
                        At the Sustainability Hub, our commitment to Justice,
                        Equity, Diversity, and Inclusivity (JEDI) is
                        foundational to our mission of creating a platform that
                        not only serves as a resource to all, but also as a
                        catalyst for transformative change. Many communities in
                        Colorado struggle with complex problems but lack access
                        to the data necessary to solve them. By harnessing AI
                        tools and aggregating existing data, the Sustainability
                        Hub intends to bridge this gap, empowering communities
                        to tackle their unique challenges effectively. We
                        understand that true sustainability cannot be achieved
                        without a deep commitment to JEDI principles
                    </p>
                    <p className="fs-4">
                        By integrating JEDI principles into every layer of our
                        project—from the selection of data sources and
                        analytical tools to our engagement strategies and
                        collaborative partnerships—we aim to empower diverse
                        voices and facilitate equitable participation, thereby
                        promoting justice and driving inclusive growth and
                        sustainability.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={3}>
                    <JediCard
                        letter="J"
                        title="Justice"
                        descriptions={descriptions.justice}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <JediCard
                        letter="E"
                        title="Equity"
                        descriptions={descriptions.equity}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <JediCard
                        letter="D"
                        title="Diversity"
                        descriptions={descriptions.diversity}
                    />
                </Col>
                <Col xs={12} lg={3}>
                    <JediCard
                        letter="I"
                        title="Inclusivity"
                        descriptions={descriptions.inclusivity}
                    />
                </Col>
            </Row>
        </Container>
    </section>
);
export default Values;
