import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';

import './HomePage.css';

import ContactInfo from '../contactUs/ContactInfo';

export default function HomePage() {
    const importAllImages = (requireContext) =>
        requireContext.keys().map((key, index) => ({
            id: index + 1,
            src: requireContext(key),
        }));
    const imgSrcList = importAllImages(
        require.context('../assets/img/carousel', false, /\.(png|jpe?g|svg)$/)
    );

    return (
        <section id="landingpage">
            <section id="homepage">
                <Container>
                    <Row>
                        <Col
                            className="cta-block"
                            lg={6}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <h1 className="cta-title text-primary text-center text-uppercase fw-bold h1">
                                Sustainability Hub
                            </h1>
                            <p className="cta-description text-primary">
                                <span>
                                    Integrating Health, Well-Being,&nbsp;
                                </span>
                                <span>
                                    and Environmental Data to Foster&nbsp;
                                </span>
                                <span>
                                    Collaboration Across Colorado for a&nbsp;
                                </span>
                                <span>Sustainable Future.</span>
                            </p>
                            <a
                                href="/about"
                                id="btn-dont-show-on-mobile"
                                className="btn btn-lg cta-btn"
                            >
                                Our Mission
                            </a>
                        </Col>
                        <Col
                            className="carousel-block m-auto"
                            lg={6}
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <Carousel controls={false}>
                                {imgSrcList.map((imgSrc) => (
                                    <Carousel.Item
                                        interval={4000}
                                        className="image-contaner"
                                        key={imgSrc.id}
                                    >
                                        <div className="image-contaner-div">
                                            <img
                                                className="center"
                                                width={748}
                                                height={412}
                                                alt="748x412"
                                                src={imgSrc.src}
                                            />
                                        </div>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Col>
                    </Row>
                    <a
                        href="/about"
                        id="btn-show-on-mobile"
                        className="btn btn-lg cta-btn"
                    >
                        Our Mission
                    </a>
                </Container>
            </section>
            <ContactInfo />
        </section>
    );
}
