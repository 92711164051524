import React from 'react';
import Button from 'react-bootstrap/Button';

const ButtonTest = () => (
    <div>
        <Button variant="secondary" size="lg" className="text-uppercase">
            push
        </Button>{' '}
    </div>
);
export default ButtonTest;
